import { useCallback, useReducer } from "react";
import { ISnailGame } from "../shared/model/uno-game";

export interface StoreState {
  state?: ISnailGame;
}

interface UpdateAction {
  type: "update";
  payload: ISnailGame;
}

type Action = UpdateAction;

const initialState: StoreState = {};

const reducer = (state: StoreState, action: Action): StoreState => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        state: action.payload,
      };
    default:
      return state;
  }
};

export const useStoreState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const update = useCallback((payload: ISnailGame) => {
    dispatch({ type: "update", payload });
  }, [dispatch]);

  return {
    state,
    update,
  };
};
