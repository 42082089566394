import React from "react";
import "./UnoBoard.css";
import { CardStack } from "../../shared/components/card-stack/CardStack";
import { Card } from "../../shared/components/card/Card";
import { UnoOponents } from "../uno-oponnents/UnoOponents";
import { useStore } from "../../store/StoreProvider";
import { UnoCard } from "../uno-card/UnoCard";
import { UnoPickCard } from "../uno-pick-card/UnoPickCard";
import SnailRoad from "../snail/road/SnailRoad";

interface UnoBoardProps {}

export const UnoBoard = (props: UnoBoardProps) => {
  return (
    <div className="game-board">
      <div className="game-board-table">
        <div className="game-board-opponents">
          <UnoOponents />
        </div>
        <SnailRoad />
        {/* <CardStack>
          <Card background={state.stack.color} content={state.stack.value} />
        </CardStack> */}
        <div className="game-board-placeholder" />
      </div>
      {/* <div className="game-board-player">
        <div className="game-board-placeholder">
          <div className="game-board-name">{}</div>
          <div className="game-board-name">
            {`${state.direction < 0 ? "↑" : "↓"} ${state.player.name}`}
            {state.temporaryColor && (
              <div
                className={`color-button ${state.temporaryColor} temporary-color`}
              />
            )}
          </div>
          <UnoPickCard />
        </div>
        <CardStack>{playerCards}</CardStack>
        <div className="game-board-placeholder " />
      </div> */}
    </div>
  );
};
