import React, { useState } from "react";
import "./SnailDice.css";

interface SnailDiceProps {
  submitDice: () => void;
  number: number | "🐌";
  color: string;
  disabled?: boolean;
}

const SnailDice = (props: SnailDiceProps) => {
  const { number, submitDice, color, disabled } = props;
  return (
    <div
      className={"dice " + (disabled ? "dice-disabled" : "")}
      onClick={submitDice}
      style={{ background: color }}
    >
      {number === "🐌" ? (
        <div className="dice-snail">🐌</div>
      ) : (
        <div className={`dot-container dot-${number}`}>
          {Array.from({ length: number }).map((_, index) => (
            <div key={index} className="dot"></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SnailDice;
