import { ISnailGame } from "../../../shared/model/uno-game";
import { apiFetch } from "../../utils/api-fetch";
import { getGameId } from "../../utils/get-game-id";
import { appConfig } from "../app-config";

type GetInitResponse = ISnailGame;

export const getInit = async () => {
  const gameId = getGameId();
  const response = await apiFetch(`${appConfig.apiUrl}game/${gameId}`);
  return (await response.json()) as GetInitResponse;
};
