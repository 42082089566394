import React, { useCallback } from "react";
import "./SnailRoad.css";
import { useStore } from "../../../store/StoreProvider";
import { SnailFieldType } from "../../../shared/model/uno-game";
import SnailDice from "./dice/SnailDice";
import { postSubmit } from "../../../store/connection/requests/post-submit";

const SnailRoad = () => {
  const { state } = useStore();

  const moveSnail = useCallback((cardId: string) => {
    postSubmit({ cardId });
  }, []);

  const canPlay = state.player.id === state.currentPlayerId;

  return (
    <div className="race-track">
      <div className="snail-player-card">
        {state.player.name}
        {state.player.card.map((color) => (
          <SingleSnail key={`snail_${color}`} color={color} />
        ))}
      </div>
      <div className="road">
        {state.board.fields.map((field, index) => (
          <div
            key={index}
            style={{
              background: LUT[field.type],
            }}
            className="field"
            id={`field-${index + 1}`}
          >
            <SnailComponent index={index} />
          </div>
        ))}
      </div>
      <div className="controls">
        {state.dices
          .filter((x) => x.visible)
          .map((dice) => {
            return (
              <SnailDice
                disabled={!canPlay}
                color={dice.color}
                number={dice.result === "🐌" ? "🐌" : parseInt(dice.result)}
                submitDice={() => {
                  if (!canPlay) return;
                  moveSnail(dice.id);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SnailRoad;

const LUT: Record<SnailFieldType, string> = {
  end: "yellow",
  leaf: "green",
  mushroom: "brown",
  start: "blue",
};

const SnailComponent = ({ index }: { index: number }) => {
  const { state } = useStore();

  const snails = state.snails.filter((x) => x.position === index);
  return (
    <React.Fragment>
      {snails.map((snail) => (
        <SingleSnail key={snail.color} color={snail.color} />
      ))}
    </React.Fragment>
  );
};

export const SingleSnail = ({ color }: { color: string }) => {
  return (
    <div className="snail" style={{ background: color }}>
      🐌
    </div>
  );
};
